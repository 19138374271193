import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Header from "../../components/Header/header"
import Footer from "../../components/Footer/footer"
import * as styles from "../../styles/product-page.module.css"
import ProductDescription from "../../components/ProductDescription/ProductDescription"

const paragraphs = [
    "New Zealand pine is also great choice for making furniture.  The performance characteristics and wood properties of New Zealand pine combine to provide a raw material easily adaptable to most furniture styles. Designers and manufacturers accept that its good technical properties and ease of finishing in natural or enhanced colours provide enormous flexibility in creating furniture styles.",
    "Whereas New Zealand pine has been quite acceptable for so called ‘low end’ furniture for many years, manufacturers are now finding the demand in upper and middle segments of the furniture market is increasing. This has generally resulted from collective industry efforts such as exhibiting at offshore trade fairs and bringing leading northern hemisphere designers to New Zealand.",
    "Opportunities for furniture made from New Zealand pine are also increasing",
]

const Furniture = () => {
    return (
        <React.Fragment>
            <Header invert={true} />

            <div className={styles.pageTitle}>
                <h1>Furniture</h1>
            </div>

            <ProductDescription text={paragraphs}>
                <StaticImage
                    src="../../images/kitchen.jpg"
                    alt="Timber kitchen"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/furniture.jpg"
                    alt="Timber furniture"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/lounge.jpeg"
                    alt="Lounge furniture"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
                <StaticImage
                    src="../../images/table.jpg"
                    alt="Pine table"
                    objectFit="cover"
                    style={{ height: "100%", width: "100%" }}
                />
            </ProductDescription>

            <Footer />
        </React.Fragment>
    )
}

export default Furniture
